import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './App.css';
import { SmartCaptcha } from '@yandex/smart-captcha';

interface ComponentWithCaptchaProps {
  onCaptchaSuccess: (token: string) => void;
}

const ComponentWithCaptcha: React.FC<ComponentWithCaptchaProps> = ({ onCaptchaSuccess }) => {
  return (
    <SmartCaptcha
      sitekey="ysc1_rBSTYhZ4UdQiFclbFHG7UWShB20EBthhcRzqkf1I93f0a70e"
      language='ru'
      onSuccess={onCaptchaSuccess}
    />
  );
};

const LoadingOverlay: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const [shouldRender, setShouldRender] = useState(isLoading);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isLoading) {
      setShouldRender(true);
      timer = setTimeout(() => setOpacity(1), 10);
    } else {
      setOpacity(0);
      timer = setTimeout(() => setShouldRender(false), 1010); 
    }
    return () => clearTimeout(timer);
  }, [isLoading]);

  if (!shouldRender) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(2px)',
      WebkitBackdropFilter: 'blur(2px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      opacity: opacity,
      transition: 'opacity 1s ease-in-out',
      pointerEvents: opacity === 0 ? 'none' : 'auto', // Отключаем взаимодействие во время исчезновения
    }}>
      <div style={{
        fontSize: '32px',
        marginBottom: '20px',
        color: '#4a90e2',
        textShadow: '2px 2px 8px rgba(0,0,0,0.1)', // Тень для текста
        padding: '10px 20px',
        maxWidth: '600px',
        alignItems: 'center',
        textAlign: 'center',
        opacity: opacity,
        transition: 'opacity 1s ease-in-out',
          // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Полупрозрачный фон для текста
        // borderRadius: '15px',
        }}>
        Удаляем персональные данные и адаптируем резюме, это может занять несколько минут...
      </div>
      <div className="loader"></div>
    </div>
  );
};

const AlertDialog: React.FC<{
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ title, onConfirm, onCancel }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <h2 style={{ marginTop: 0 }}>{title}</h2>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <input
            type="checkbox"
            id="policyCheckbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            style={{ 
              marginRight: '10px',
              width: '25px',
              height: '25px',
              cursor: 'pointer'
            }}
          />
          <label htmlFor="policyCheckbox" >
            Я ознакомился с {' '}
            <a 
              href="/privacy" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ color: '#4a90e2' }}
            >
              Политикой в отношении обработки персональных данных
            </a>
          </label>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <button 
            onClick={onCancel} 
            style={{ 
              marginRight: '10px', 
              padding: '8px 16px', 
              cursor: 'pointer', 
              backgroundColor: '#333', 
              color: 'white', 
              border: 'none', 
              borderRadius: '4px' 
            }}
          >
            Отказаться
          </button>
          <button 
            onClick={onConfirm} 
            disabled={!isChecked}
            style={{ 
              padding: '8px 16px', 
              backgroundColor: isChecked ? '#4a90e2' : '#ccc', 
              color: 'white', 
              border: 'none', 
              borderRadius: '4px', 
              cursor: isChecked ? 'pointer' : 'not-allowed' 
            }}
          >
            Принимаю Политику
          </button>
        </div>
      </div>
    </div>
  );
};

const SectionWrapper = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  ({ children }, ref) => (
    <div
      ref={ref}
      style={{
        background: 'linear-gradient(to bottom, #f5f5f5, #ffffff)',
        padding: '20px',
        marginBottom: '0px',
        borderRadius: '8px',
      }}
    >
      {children}
    </div>
  )
);

const App: React.FC = () => {
  const [showPolicy, setShowPolicy] = useState(false);
  const [showResumeSection, setShowResumeSection] = useState(false);
  const [jobDescription, setJobDescription] = useState('');
  const [resume, setResume] = useState('');
  const [adaptedResume, setAdaptedResume] = useState('');
  const [isAdapting, setIsAdapting] = useState(false);
  const [error, setError] = useState('');
  const [showAdaptButton, setShowAdaptButton] = useState(true);
  const resumeSectionRef = useRef<HTMLDivElement>(null);
  const jobDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const adaptedResumeRef = useRef<HTMLDivElement>(null);
  const adaptedResumeSectionRef = useRef<HTMLDivElement>(null);
  const [copyButtonText, setCopyButtonText] = useState('Копировать текст');
  const isAdaptButtonDisabled = jobDescription.length <= 100 || resume.length <= 100;
  const [captchaPassed, setCaptchaPassed] = useState<boolean>(false);

  const handleCaptchaSuccess = (token: string) => {
    console.log('Captcha passed with token:', token);
    setCaptchaPassed(true);
  };

  const handleAcceptPolicy = () => {
    setShowPolicy(false);
    setShowResumeSection(true);
  };

  const handleAdaptResume = async () => {
    setIsAdapting(true);
    setError('');

    try {
      const response = await axios.post('/api/resume', {
        job_description_text: jobDescription,
        resume_text: resume
      }, {
        timeout: 5 * 60 * 1000
      });

      if (response.status === 200 && response.data.result === 'OK') {
        setAdaptedResume(response.data.resume_text);
        setShowAdaptButton(false); 
      } else {
        throw new Error('Unexpected response');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ECONNABORTED') {
          setError('Запрос к серверу прерван из-за ожидания более 5 минут. Попробуйте повторить запрос позже');
        } else {
          setError('К сожалению, произошла ошибка. Попробуйте повторить запрос позже');
        }
      } else {
        setError('К сожалению, произошла ошибка. Попробуйте повторить запрос позже');
      }
    } finally {
      setIsAdapting(false);
    }
  };

  const handleCopyToClipboard = () => {
    if (adaptedResumeRef.current) {
      const range = document.createRange();
      range.selectNodeContents(adaptedResumeRef.current);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges(); // Убираем выделение после копирования
        setCopyButtonText('Текст скопирован');
  
        // Возвращаем текст кнопки через 5 секунд
        setTimeout(() => {
          setCopyButtonText('Копировать текст');
        }, 5000);
      }
    }
  };

  useEffect(() => {
    if (adaptedResumeRef.current) {
      let content = adaptedResumeRef.current.innerHTML;

      // Заменяем все группы "X" и "(необходимо подтверждение кандидата!)" на HTML с подсветкой
      content = content.replace(/X+/g, '<span style="color: red;">$&</span>');
      content = content.replace(/\(необходимо подтверждение кандидата!\)/g, '<span style="color: red;">$&</span>');

      // Обновляем содержимое div с подсветкой
      adaptedResumeRef.current.innerHTML = content;
    }
  }, [adaptedResume]); // Этот эффект будет вызван каждый раз, когда изменяется adaptedResume

  useEffect(() => {
    if (showResumeSection && resumeSectionRef.current) {
      resumeSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      // Устанавливаем таймаут, чтобы дать время для прокрутки
      setTimeout(() => {
        if (jobDescriptionRef.current) {
          jobDescriptionRef.current.focus();
        }
      }, 500); // 500ms должно быть достаточно для завершения прокрутки
    }
  }, [showResumeSection]);

  useEffect(() => {
    if (adaptedResume && adaptedResumeRef.current && adaptedResumeSectionRef.current) {
      adaptedResumeSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        if (adaptedResumeRef.current) {
          adaptedResumeRef.current.focus();
        }
      }, 500);
    }
  }, [adaptedResume]);  

  const textStyle = {
    // fontFamily: 'ui-sans-serif,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica,Apple Color Emoji,Arial,Segoe UI Emoji,Segoe UI Symbol',
    color: '#333',
    lineHeight: '1.6',
    smooth : 'always'
  };

  const textareaStyle: React.CSSProperties = {
    width: 'calc(100% - 20px)',
    minHeight: '150px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    resize: 'vertical' as const,
    marginLeft: '0px',
    marginRight: '0px',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    outline: 'none', 
    boxShadow: 'none',
    // fontFamily: 'ui-sans-serif,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica,Apple Color Emoji,Arial,Segoe UI Emoji,Segoe UI Symbol',
  };

  const textareaFocusStyle: React.CSSProperties = {
    borderColor: '#4a90e2',
    boxShadow: '0 0 0 2px rgba(74, 144, 226, 0.2)',
  };
  
  return (
    
    <div style={{ ...textStyle, maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <SectionWrapper>
        <h1 style={{ textAlign: 'center', color: '#333' }}>Адаптация резюме под описание вакансии</h1>
        <p style={{ textAlign: 'center', color: '#666', marginBottom: '30px', fontWeight: 'bold' }}>
          Увеличьте свои шансы получить работу! Получите персонализированное резюме и сопроводительное письмо за считанные минуты.
        </p>
        
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0px' }}>
          <a href="https://assistnow.ru" target="_blank" rel="noopener noreferrer">
            <img 
              src="/images/hero2.jpeg" 
              alt="AI-ассистенты для 1С" 
              style={{ 
                width: '250px', 
                height: 'auto', 
                borderRadius: '8px',
                boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer'
              }} 
            />
          </a>
        </div>
        <p style={{ textAlign: 'center', color: '#666', fontSize: '8pt' }}>
          <a href="https://assistnow.ru" target="_blank" rel="noopener noreferrer">
            AI-ассистенты для 1С
          </a> и другие возможности применения AI для бизнеса
        </p>
      </SectionWrapper>

      <SectionWrapper>
        <h2 style={{ textAlign: 'center', color: '#333' }}>Наш сервис - ваш путь к успешному трудоустройству</h2>
        <p style={{ textAlign: 'center', color: '#666' }}>
          Вам могут отказать в работе, если ваше резюме не в полной мере подходит под описание вакансии. 
          Такую проверку в наше время часто проводят роботы. И проверяют они по формальным признакам. 
          Какой бы вы ни были прекрасный специалист, с вами могут даже не поговорить. 
          Поэтому 8 из 10 HR-специалистов говорят о том, что для успешного трудоустройства 
          нужно обязательно адаптировать ваше резюме и сопроводительное письмо
          под конкретную вакансию. Наш инновационный сервис автоматически анализирует
          описание вакансии и оптимизирует ваше резюме, чтобы оно максимально соответствовало
          требованиям работодателя. Забудьте о часах, потраченных на ручную адаптацию -
          доверьте эту задачу нам и сосредоточьтесь на подготовке к собеседованию!
        </p>
      </SectionWrapper>

      {!showResumeSection && (
        <div style={{ 
          height: '100px', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center'
        }}>
          {!captchaPassed ? (
            <ComponentWithCaptcha onCaptchaSuccess={handleCaptchaSuccess} />
          ) : (
            <button 
              onClick={() => setShowPolicy(true)}
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                backgroundColor: '#4a90e2',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
              }}
            >
              Продолжить
            </button>
          )}
        </div>
      )}

      {showPolicy && (
        <AlertDialog
          title="Политика использования персональных данных"
          onConfirm={handleAcceptPolicy}
          onCancel={() => setShowPolicy(false)}
        />
      )}

      {showResumeSection && (
        <SectionWrapper ref={resumeSectionRef}>
          <div style={{ marginBottom: '20px' }}>
            <h2 style={{ color: '#333' }}>Описание вакансии</h2>
            <textarea
              ref={jobDescriptionRef}
              value={jobDescription}
              placeholder='Вставьте сюда описание вакансии, под требования которой необходимо адаптировать ваше резюме'
              onChange={(e) => setJobDescription(e.target.value)}
              style={textareaStyle}
              onFocus={(e) => Object.assign(e.target.style, textareaFocusStyle)}
              onBlur={(e) => Object.assign(e.target.style, textareaStyle)}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h2 style={{ color: '#333' }}>Ваше резюме</h2>
            <textarea
              value={resume}
              placeholder={`- Вставьте сюда описание ваших достижений и опыта
- Если у вас нет резюме, то укажите хотя бы годы работы в разных компаниях
- КОНТАКТЫ И ПЕРСОНАЛЬНЫЕ ДАННЫЕ НЕ ВСТАВЛЯЙТЕ`}
              onChange={(e) => setResume(e.target.value)}
              style={textareaStyle}
              onFocus={(e) => Object.assign(e.target.style, textareaFocusStyle)}
              onBlur={(e) => Object.assign(e.target.style, textareaStyle)}
            />
          </div>
          {showAdaptButton && (
            <div style={{ textAlign: 'center' }}>
              <button 
                onClick={handleAdaptResume}
                disabled={isAdaptButtonDisabled}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: isAdaptButtonDisabled ? '#ccc' : '#4a90e2',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: isAdaptButtonDisabled ? 'not-allowed' : 'pointer',
                  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
                }}
              >
                Адаптировать резюме
              </button>
            </div>
          )}
          {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>{error}</p>}
        </SectionWrapper>
      )}

      {adaptedResume && (
        <SectionWrapper ref={adaptedResumeSectionRef}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 style={{ color: '#333' }}>Адаптированное резюме</h2>
            <button
                onClick={handleCopyToClipboard}
                style={{
                  padding: '8px 16px',
                  backgroundColor: '#4a90e2',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
                }}
              >
                {copyButtonText}
              </button>
          </div>
          <div
            ref={adaptedResumeRef}
            className='markdown-content'
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              minHeight: '500px',
              // maxHeight: '800px',
              overflow: 'auto',
              // whiteSpace: 'pre-wrap',
              // fontFamily: 'Arial, sans-serif',
              // fontSize: '14px',
              // lineHeight: '1.6',
              backgroundColor: 'white',
            }}
          >
            <ReactMarkdown>{adaptedResume}</ReactMarkdown>
          </div>
        </SectionWrapper>
      )}

      {/* {isAdapting && <LoadingOverlay isLoading={isAdapting} />} */}
      <LoadingOverlay isLoading={isAdapting} />

      <style>{`
        .loader {
          border: 5px solid #f3f3f3;
          border-top: 5px solid #3498db;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>

      <div style={{
        backgroundColor: 'white',
        padding: '20px 20px 0px 20px',
        marginTop: '40px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
        fontSize: '10pt',
      }}>
        <div>
          <a href="https://t.me/AIAssistNow" target="_blank" rel="noopener noreferrer"> 
            Контакт для обратной связи
          </a>
        </div>
        <div>
          <a href="/privacy" target="_blank" rel="noopener noreferrer">
            Политика обработки персональных данных
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;